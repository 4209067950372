<template>
  <v-data-table
    :headers="headers"
    :items="patterns"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>

        <v-dialog
          v-model="dialog"
          max-width="1300"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <div class="d-flex justify-space-between"> -->
            <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="close"
            >
              เพิ่มลายสินค้า
            </v-btn>
            <!-- </div> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-row>
                      <v-col
                        v-if="editedItem && editedItem.pattern_img_product && editedItem.pattern_img_product.pattern_img"
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-img
                          :src="editedItem.pattern_img_product.pattern_img"
                        ></v-img>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-file-input

                          v-model="editedItem.pattern_img"
                          label="รูปลายสินค้า"
                        ></v-file-input>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.pattern_img.$silentErrors"
                          v-if="v$.editedItem.pattern_img.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>


                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.pattern_code"
                          label="รหัสลายสินค้า"
                          placeholder="รหัสลายสินค้า"
                        ></v-text-field>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.pattern_code.$silentErrors"
                          v-if="v$.editedItem.pattern_code.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.pattern_name"
                          label="ชื่อลายสินค้า"
                          placeholder="ชื่อลายสินค้า"
                        ></v-text-field>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.pattern_name.$silentErrors"
                          v-if="v$.editedItem.pattern_name.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <label for="">รูปแบบการแสดง</label>
                        <v-checkbox v-model="editedItem.pattern_status"
                                    value="Y"
                                    hide-details dense
                                    label="แสดงหน้าเว็บไซต์/ประภาศแจ้งเตือน"></v-checkbox>

                        <v-checkbox v-model="editedItem.pattern_new"
                                    value="Y"
                                    hide-details dense label="สินค้าใหม่"></v-checkbox>

                        <v-checkbox v-model="editedItem.pattern_recommend"
                                    value="Y"
                                    hide-details dense label="สินค้าแนะนำ"></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <p>รายละเอียดสินค้า</p>
                        <ckeditor :editor="editor"
                                  label="รายละเอียดสินค้า"
                                  v-model="editedItem.pattern_detail"
                                  :config="editorConfig"
                        ></ckeditor>
                      </v-col>

                    </v-row>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.similar"
                          label="ชื่อสินค้าคล้ายกัน"
                          placeholder="ชื่อสินค้าคล้ายกัน"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-select
                          v-model="editedItem.product_type_id"
                          :items="product_types"
                          item-text="product_type_name"
                          item-value="product_type_id"
                          placeholder="ประเภทลายสินค้า"
                          label="ประเภทสินค้า"
                        ></v-select>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          type="number"
                          min="1"
                          v-model="editedItem.price_start"
                          label="ราคาเริ่มต้น"
                          placeholder="ราคาเริ่มต้น"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          type="number"
                          min="0"
                          v-model="editedItem.discount"
                          label="ส่วนลด %"
                          placeholder="ส่วนลด"
                        ></v-text-field>
                      </v-col>


                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <div v-if="editedItem.pattern_media && editedItem.pattern_media.length > 0">
                          <h2>รูปประเภทสินค้ารูปลายเพิ่มเติม</h2>
                          <div v-for="(item,index) in editedItem.pattern_media">
                            <v-row>
                              <v-col
                                cols="8"
                                sm="8"
                                md="8">

                                <p>
                                  {{ item.product_type ? item.product_type.product_type_name : '-' }}</p>
                                <v-img
                                  width="50px"
                                  :src="item.media.path"
                                  :alt="item.media.path"
                                ></v-img>

                              </v-col>
                              <v-col
                                cols="2"
                                sm="2"
                                md="1">

                                <v-btn
                                  @click="deleteMedia(index)"
                                  color="red">
                                  ลบ
                                </v-btn>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                        <!--                        @foreach($data->pattern_media as $index => $pattern_media)-->
                        <!--                        <p>{{$data->toArray()['pattern_media'][$index]['product_type']['product_type_name']}}</p>-->
                        <!--                        @if($pattern_media->media && $pattern_media->media->path)-->
                        <!--                        <img src="{{$pattern_media->media->path}}" style="width: 30%" alt="{{$pattern_media->media->name}}">-->

                        <!--                        <a disabled="1" onclick="return confirm('คุณต้องการลบข้อมูลใช่หรือไม่?')" href="{{url('/admin/pattern_media_remove'.'/'.$pattern_media->id)}}"><i style="width: 25%" class="fas fa-trash text-danger"></i></a>-->
                        <!--                        @endif-->
                        <!--                        <br>-->
                        <!--                        <br>-->
                        <!--                        <hr>-->
                        <!--                        <br>-->
                        <!--                        <br>-->
                        <!--                        @endforeach-->
                        <!--                        @else-->
                        <!--                        <h2>ไม่พบรูปภาพ ประเภทสินค้ารูปลายเพิ่มเติม</h2>-->
                        <!--                        @endif-->
                        <v-file-input
                          v-model="editedItem.PatternMediaFiles"
                          class="mt-5"
                          hide-details
                          multiple
                          dense
                          label="รูปประเภทสินค้ารูปลายเพิ่มเติม"
                        ></v-file-input>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-select
                          v-model="editedItem.file_product_type_id"
                          :items="product_types"
                          item-text="product_type_name"
                          item-value="product_type_id"
                          placeholder="ประเภทสินค้ารูปลายเพิ่มเติม"
                          label="ประเภทสินค้ารูปลายเพิ่มเติม"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-checkbox
        value="Y"
        @click="updateStatusPackage(item.package_id)"
        v-model="item.package_status">
      </v-checkbox>
    </template>
    <template v-slot:item.file="{ item }">

      <!--      <a v-if="!item.pattern_img" :href="item.location_files" target="_blank">ไฟล์เพิ่มเติม</a>-->
      <a
        v-if="item.pattern_img_product"
        target="_blank" :href="item.pattern_img_product.pattern_img">
        <v-img
          width="30"
          :src="item.pattern_img_product ? item.pattern_img_product.pattern_img :''"
        ></v-img>
      </a>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:item.promotion_free="{ item }">
      <span v-if="item.start_promotion && item.end_promotion">
        วันที่เริ่ม {{ item.start_promotion ? item.start_promotion : '-' }} <br>
        วันที่สิ้นสุด {{ item.end_promotion ? item.end_promotion : '-' }}
      </span>
      <span v-if="!item.start_promotion && !item.end_promotion">
        -
      </span>
    </template>

    <template v-slot:item.status1="{ item }">
      <v-checkbox
        value="Y"
        dense
        hide-details
        @click="UpdateStatus(item.pattern_id,1)"
        v-model="item.pattern_status"
      ></v-checkbox>
    </template>

    <template v-slot:item.status2="{ item }">
      <v-checkbox
        value="Y"
        dense
        hide-details
        @click="UpdateStatus(item.pattern_id,2)"
        v-model="item.pattern_new"
      ></v-checkbox>
    </template>
    <template v-slot:item.status3="{ item }">
      <v-checkbox
        value="Y"
        dense
        hide-details
        @click="UpdateStatus(item.pattern_id,3)"
        v-model="item.pattern_recommend"
      ></v-checkbox>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
import DatePicker from 'vue2-datepicker'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_pattern from '../../../services/pattern'
import instance_product_type from '../../../services/productType'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";
import {useVuelidate} from '@vuelidate/core'
import {required, email, minLength, sameAs} from '@vuelidate/validators'

export default {
  components: {DatePicker},
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    serverOptions: {
      page: 1,
      itemsPerPage: 10,
    },
    total: 0,
    editor: ClassicEditor,
    editorConfig: {},
    patterns: [],
    product_types: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false
      },
      {text: 'รหัสลายสินค้า', value: 'pattern_code'},
      {text: 'ชื่อลายสินค้า', value: 'pattern_name'},
      {text: 'ไฟล์', value: 'file', sortable: false},
      {text: 'สถานการใช้งาน (เปิด/ปิด)', value: 'status1', sortable: false},
      {text: 'สถานะ ลายใหม่(เปิด/ปิด)', value: 'status2', sortable: false},
      {text: 'สถานะ ลายแนะนำ(เปิด/ปิด)', value: 'status3', sortable: false},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      pattern_img: null,
      PatternMediaFiles: null,
      pattern_id: '',
      pattern_code: '',
      pattern_name: '',
      pattern_status: 'N',
      pattern_new: 'N',
      pattern_recommend: 'N',
      price_start: '',
      discount: '',
      product_type_id: '',
      pattern_detail: '',
      similar: '',
      file_product_type_id: '',
    },
    defaultItem: {
      pattern_img: null,
      PatternMediaFiles: null,
      pattern_id: '',
      pattern_code: '',
      pattern_name: '',
      pattern_status: 'N',
      pattern_new: 'N',
      pattern_recommend: 'N',
      price_start: '',
      discount: '',
      product_type_id: '',
      pattern_detail: '',
      similar: '',
      file_product_type_id: '',
    },
  }),
  validations() {

    var validate = {
      editedItem: {

        pattern_code: {required},
        pattern_name: {required},
        pattern_img: {},
      }
    }

    if (this.editedIndex == -1) {
      validate.editedItem.pattern_img = {required};
    }
    return validate
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม ลายสินค้า' : 'แก้ไข ลายสินค้า'
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getPattern()
      },
    },
    search: {
      handler() {
        this.getPattern()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getPattern()
    this.getProductType()
  },

  methods: {
    deleteMedia(index) {
      this.$store.state.isLoading = true
      var id = this.editedItem.pattern_media[index].id
      instance_pattern.deleteMedia(id).then(res => {
        Swal.fire({
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500
        })
        this.editedItem.pattern_media.splice(index, 1)
        this.$store.state.isLoading = false
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false

      });
    },
    editItem(item) {
      this.v$.$reset()
      this.editedIndex = this.patterns.indexOf(item)
      this.editedItem = {...item}
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.patterns.indexOf(item)
      this.editedItem = {...item}
      this.dialogDelete = true
    },
    UpdateStatus(id, type) {
      this.$store.state.isLoading = true
      var data = {
        id: id,
        type: type
      }
      instance_pattern.changeStatus(data).then(res => {
        Swal.fire({
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500
        })
        this.$store.state.isLoading = false

      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false

      });
    },
    deleteItemConfirm() {
      this.$store.state.isLoading = true
      instance_pattern.destroy(this.editedItem).then(res => {
        setTimeout(function () {
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500
          })
        }, 150);
        this.getPattern();
        this.closeDelete()
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false

      })
    },

    close() {
      this.v$.$reset()
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      this.$store.state.isLoading = true
      if (this.editedIndex > -1) {
        instance_pattern.update(this.editedItem)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            }, 150);
            this.getPattern();
            this.close()
          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      } else {
        instance_pattern.store(this.editedItem)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            }, 150);
            this.getPattern();
            this.close()
          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      }
    },
    getPattern() {
      this.$store.state.isLoading = true
      var data = {
        search: this.search,
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        sortBy: this.sortBy ?? "",
        sortDesc: this.sortDesc ?? ''
      }

      instance_pattern.get(data).then(res => {
        this.patterns = res.data.data.data;
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
    getProductType() {
      this.$store.state.isLoading = true
      instance_product_type.all().then(res => {
        this.product_types = res.data.data;
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
